<template>
<div>
  <h1>
    Books returned
    <template v-if="student">by {{ student.FIRST_NAME }} {{ student.LAST_NAME }}</template>
    on {{ date(batch.date) }}
  </h1>
  <ul>
    <li v-for="book, i in booksReturned" :key="i">
      {{ book['Book Title'] }} ({{ book['Course Name'] }})
      
      <template v-if="student && (book.student_id !== student.id)">
        -- Had been checked out to {{ book.ramaz_bookroom_student.FIRST_NAME }} {{ book.ramaz_bookroom_student.LAST_NAME }}
      </template>
      <template v-if="book.student_id && !student"> 
        -- Had been checked out to {{ book.ramaz_bookroom_student.FIRST_NAME }} {{ book.ramaz_bookroom_student.LAST_NAME }}
      </template>
      
    </li>
  </ul>
  
  <h1 v-if="student">
    Books assigned to {{ student.FIRST_NAME }} {{ student.LAST_NAME }}
    that are still outstanding
  </h1>
  
  <div class="container" v-if="booksNotReturned.length">
    <table class="table">
      <tr v-for="book, i in booksNotReturned" :key="i">
        <td class="text-left">
          {{ book['Book Title'] }} ({{ book['Course Name'] }})
        </td>
        
        <td>
          <template v-if="book['Consumable']">
            (consumable)
          </template>
          <template v-else-if="book.paid">
            (already paid)
            <button class="btn btn-primary" @click.prevent="markAsUnpaid(book.id)">
              Mark as unpaid, actually
            </button>
          </template>
          <template v-else>
            <button class="btn btn-primary" @click.prevent="markAsPaid(book)">
              Mark as paid
              <template v-if="book.ramaz_bookroom_bookrecord">
                (${{ book.ramaz_bookroom_bookrecord.price }})
              </template>
            </button>
          </template>
        </td>
      </tr>
    </table>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      batch: {},
      booksReturned: [],
      booksNotReturned: [],
    };
  },
  computed: {
    student () {
      if (!this.batch) return null;
      if (!this.batch.ramaz_bookroom_student) {
        return null;
      }
      return this.batch.ramaz_bookroom_student;
    },
  },
  methods: {
    date: o => (new Date(o)).toLocaleDateString(),

    bookCourse: o => {
      if (o['Course Name']) {
        return `(${o['Course Name']})`;
      }
      return '';
    },
    
    bookTitle: o => {
      if (o['Book Title']) {
        return o['Book Title'];
      }
      if (o['ISBN']) {
        return `ISBN: ${o['ISBN']}`;
      }
      return `A book with barcode ${o['Scanned Book Barcode']}`;
    },
    
    async markAsUnpaid (id) {
      const { data } = await this.$supabase
            .from('ramaz_bookroom_bookassignment')
            .update({ paid: false })
            .eq('id', id);
      this.booksNotReturned = this.booksNotReturned.map(o => {
        if (o.id === id) {
          return data[0];
        } else {
          return o;
        }
      });
    },
        
    async markAsPaid (book) {

      if (!confirm(`Are you sure you want to mark ${this.bookTitle(book)} as paid for?`)) {
        return;
      }
      const id = book.id;
      
      const { data } = await this.$supabase
            .from('ramaz_bookroom_bookassignment')
            .update({ paid: true })
            .eq('id', id);
      this.booksNotReturned = this.booksNotReturned.map(o => {
        if (o.id === id) {
          return data[0];
        } else {
          return o;
        }
      });
    },
    
    async fetchBatch () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_returnbatch")
            .select("*, ramaz_bookroom_student!fk_student_id(*)")
            .eq('id', this.$route.params.id)
            .maybeSingle();
      
      this.batch = data;
    },

    async fetchBooksNotReturned () {
      if (!this.student) {
        return;
      }
      const { data } = await this.$supabase
            .from("ramaz_bookroom_bookassignment")
            .select("*, ramaz_bookroom_bookrecord(*)")
            .eq("student_id", this.student.id)
            .eq("returned", false);
      this.booksNotReturned = data;
    },
    
    async fetchBooksReturned () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_bookassignment")
            .select("*, ramaz_bookroom_student(*)")
            .eq("returned_in_batch_id", this.$route.params.id);
      this.booksReturned = data;
    },
  },
  async mounted () {
    await Promise.all([ this.fetchBatch(), this.fetchBooksReturned() ]);
    await this.fetchBooksNotReturned();
  },
}
</script>
