<template>
<div>
  <div v-if="student">
    <h1>
      Books returned by {{ student.FIRST_NAME }} {{ student.LAST_NAME }}
    </h1>
  
    <ul v-if="booksReturned.length">
      <li v-for="book, i in booksReturned" :key="i">
        {{ book['Book Title'] }} ({{ book['Course Name'] }})
        
        <template v-if="student && (book.student_id !== student.id)">
          -- Checked out to {{ book.ramaz_bookroom_student.FIRST_NAME }} {{ book.ramaz_bookroom_student.LAST_NAME }}
        </template>
        
        -- Returned on {{ date(book.ramaz_bookroom_returnbatch.date) }}
        <template v-if="book.ramaz_bookroom_returnbatch.manual_checkin">via manual check-in</template>
      </li>
    </ul>
    <p v-else>
      No books have been returned yet.
    </p>
    
  </div>
  <div class="mt-3" v-if="student">
    <h1>
      Books assigned to {{ student.FIRST_NAME }} {{ student.LAST_NAME }}
      that are still outstanding
    </h1>

    <div class="container" v-if="booksNotReturned.length">
      <table class="table">
        <tr v-for="book, i in booksNotReturned" :key="i">
          <td class="text-left">
            {{ bookTitle(book) }}
            {{ bookCourse(book) }}
          </td>

          <td>
            <button class="btn btn-primary" @click.prevent="checkinManually(book)">
              Check in manually!
            </button>
          </td>
          
          <td>
            <template v-if="book['Consumable']">
              (consumable)
            </template>
            <template v-else-if="book.paid">
              (already paid)
              <br>
              <br>
              <button class="btn btn-secondary" @click.prevent="markAsUnpaid(book.id)">
                Mark as unpaid, actually
              </button>
            </template>
            <template v-else>
              <button class="btn btn-secondary" @click.prevent="markAsPaid(book)">
                Mark as paid
                <template v-if="book.ramaz_bookroom_bookrecord">
                  (${{ book.ramaz_bookroom_bookrecord.price }})
                </template>
              </button>
            </template>
          </td>
        </tr>
      </table>
    </div>
    <p v-else>
      No books are outstanding!
    </p>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      student: '',
      batches: [],
      booksReturned: [],
      booksNotReturned: [],
    };
  },
  
  methods: {

    bookCourse: o => {
      if (o['Course Name']) {
        return `(${o['Course Name']})`;
      }
      return '';
    },
    
    bookTitle: o => {
      if (o['Book Title']) {
        return o['Book Title'];
      }
      if (o['ISBN']) {
        return `ISBN: ${o['ISBN']}`;
      }
      return `A book with barcode ${o['Scanned Book Barcode']}`;
    },
    
    date: o => (new Date(o)).toLocaleDateString(),

    async checkinManually (book) {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_returnbatch")
            .insert([{
              date: (new Date()),
              student_id: this.$route.params.id,
              manual_checkin: true,
            }]);
      const returned_in_batch_id = data[0].id;

      await this.$supabase
        .from("ramaz_bookroom_bookassignment")
        .update({
          returned: true,
          returned_in_batch_id,
        })
        .eq('id', book.id);

      window.location.reload();
    },
    
    async markAsUnpaid (id) {
      const { data } = await this.$supabase
            .from('ramaz_bookroom_bookassignment')
            .update({ paid: false })
            .eq('id', id);
      this.booksNotReturned = this.booksNotReturned.map(o => {
        if (o.id === id) {
          return data[0];
        } else {
          return o;
        }
      });
    },
    
    async markAsPaid (book) {
      if (!confirm(`Are you sure you want to mark ${this.bookTitle(book)} as paid for?`)) {
        return;
      }
      const id = book.id;
            
      const { data } = await this.$supabase
            .from('ramaz_bookroom_bookassignment')
            .update({ paid: true })
            .eq('id', id);
      this.booksNotReturned = this.booksNotReturned.map(o => {
        if (o.id === id) {
          return data[0];
        } else {
          return o;
        }
      });
    },
    
    async fetchBatches () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_returnbatch")
            .select("*, ramaz_bookroom_student!fk_student_id(*)")
            .eq('student_id', this.$route.params.id);
      
      this.batches = data;
    },
    
    async fetchBooksNotReturned () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_bookassignment")
            .select("*, ramaz_bookroom_bookrecord(*)")
            .eq("student_id", this.$route.params.id)
            .eq("returned", false);
      this.booksNotReturned = data;
    },
    
    async fetchBooksReturned () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_bookassignment")
            .select("*, ramaz_bookroom_returnbatch(*), ramaz_bookroom_student(*)")
            .in("returned_in_batch_id", this.batches.map(o => o.id));
      this.booksReturned = data;
    },

    async fetchStudent () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_student")
            .select("*")
            .eq('id', this.$route.params.id)
            .maybeSingle();
      
      this.student = data;
    },
  },

  async mounted () {

    await Promise.all([this.fetchBooksNotReturned(), this.fetchBatches(), this.fetchStudent()]);

    await this.fetchBooksReturned();
  },
}
</script>
