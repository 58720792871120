import { createApp } from 'vue'
import VueSupabase from 'vue-supabase'

import App from './App.vue'

const app = createApp(App)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

app.component('v-select', vSelect)

import { createRouter, createWebHistory } from 'vue-router'

import HelloWorld from './components/HelloWorld.vue'
import StudentView from './components/StudentView.vue';
import BatchView from './components/BatchView.vue';
import StudentList from './components/StudentList.vue';

const routes = [
  { path: '/', component: HelloWorld },
  { path: '/batch/:id', component: BatchView },  
  { path: '/students/:id', component: StudentView },
  { path: '/students', component: StudentList },  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
app.use(router)

import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      logged_in: process.env.ALWAYS_LOGGED_IN,
    }
  },

  mutations: {
    login (state) {
      state.logged_in = true;
    },
    logout (state) {
      state.logged_in = false;
    },
  },
});

app.use(store)


app.use(VueSupabase, {
  supabaseUrl: 'https://yqcbkburpvbgtoahlbco.supabase.co',
  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlxY2JrYnVycHZiZ3RvYWhsYmNvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTQ0NTg1OTIsImV4cCI6MTk3MDAzNDU5Mn0.wles3uFlw3bRnJlsER_Rerr-d5lFR3njaXZMGD5G3P0',

//  supabaseUrl: 'https://jzakkbpccksdaxzjrivy.supabase.co',
//  supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imp6YWtrYnBjY2tzZGF4empyaXZ5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDU3NTAwNTIsImV4cCI6MTk2MTMyNjA1Mn0.Ifs1vUWSBjm8CDANy2l1JMuSuUqGN2IxSx5-KgxvFqc',
})

app.mount('#app')
