<template>
<div class="container text-left">
  
  <h1>Welcome to the bookroom!</h1>
  
  <div class="row">
    <div class="col-12 col-md-8">
      <div class="form-row">
        <h2>
          Student returning books: 
        </h2>
        <v-select v-model="student" :options="studentNames"></v-select>
      </div>
      
      <div class="form-row">
        <h2>Books returned:</h2>
        <form @submit.prevent="lookup">
          <input v-model="barcode" class="form-control" />
        </form>
      </div>
    </div>
    <div class="col">

      <div v-if="books.length && student">
        <button @click="checkin" class="btn btn-primary">Check these books back in</button>
      </div>
      
      <div class="book-list">
        <div class="book" v-for="book in books" :key="book.id">
          <div class="book-chip">
            {{ bookTitle(book) }}
            {{ bookCourse(book) }}
            
            <template v-if="student && (book.student_id !== student.id)">
              Checked out to {{ book.ramaz_bookroom_student.FIRST_NAME }} {{ book.ramaz_bookroom_student.LAST_NAME }}
            </template>
            
            <span class="close" @click="removeBook(book)">
              ✖
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  computed: {
    studentNames () {
      const students = this.students.map(o => ({
        ...o,
        label: `${o.FIRST_NAME} ${o.LAST_NAME} (Grade ${o.CURRENT_GRADE})`,
      }));

      return [
        {
          label: '[No student is returning these books]',
          id: null,
        },
        ...students
      ];
    },
  },
  methods: {

    bookCourse: o => {
      if (o['Course Name']) {
        return `(${o['Course Name']})`;
      }
      return '';
    },
    
    bookTitle: o => {
      if (o['Book Title']) {
        return o['Book Title'];
      }
      if (o['ISBN']) {
        return `ISBN: ${o['ISBN']}`;
      }
      return `A book with barcode ${o['Scanned Book Barcode']}`;
    },
    
    removeBook (book) {
      this.books = this.books.filter(o => o !== book);
    },
    async checkin () {
      const { data } = await this.$supabase
            .from("ramaz_bookroom_returnbatch")
            .insert([{
              date: (new Date()),
              student_id: this.student.id,
            }]);
      const returned_in_batch_id = data[0].id;

      await this.$supabase
        .from("ramaz_bookroom_bookassignment")
        .update({
          returned: true,
          returned_in_batch_id,
        })
        .in('id', this.books.map(o => o.id));

      this.$router.push(`/batch/${returned_in_batch_id}`);
    },
    
    async lookup () {
      let barcodes = [ this.barcode ];
      if (this.barcode.startsWith('0')) {
        barcodes.push(
          this.barcode.replace(/^(0+)/, '')
        );
      }
      
      let { data, error } = await this.$supabase
            .from("ramaz_bookroom_bookassignment")
            .select("*, ramaz_bookroom_student(*)")
            .in('Scanned Book Barcode', barcodes);

      if (error) {
        alert(JSON.stringify(error));
        return;
      }
      
      if (!data || !data.length) {
        alert('Book not found');
        return;
      }

      if (data.length > 1) {
        alert(`Found ${data.length} books found with barcode ${this.barcode}, but we expected only one! Please record this barcode and send it to the system administrators for further review.`);
        return;
      }
      
      data = data[0];
      
      if (this.books.find(o => o.id === data.id)) {
        alert(`Looks like you already scanned this book: ${data['Book Title']}`);
        return;
      }

      if (data.returned) {
        alert(`Uh oh! This book has already been marked as returned: ${data['Book Title']}`);
        return;
      }

      this.books.push(data);
      this.barcode = '';
    }
  },
  data () {
    return {
      barcode: '',
      books: [],
      students: [],
      student: null,
    }
  },
  async mounted () {
    const { data } = await this.$supabase.from("ramaz_bookroom_student").select("*");
    this.students = data;
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.book-list {
  display: flex;
  flex-wrap: wrap;
}
.book {
  width: 100%;
  margin: .5rem 0;
}
.book-chip {
  border-radius: 25px;
  background: lightgray;
  padding: .5rem .8rem;
  font-size: .8rem;
  display: inline;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book-chip .close {
  margin-left: 1rem;
  font-size: .8rem;
}
.form-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.form-row h2 {
  flex-shrink: 0;
}
.form-row form,
.form-row .v-select {
  max-width: 100%;
  flex: 1;
  margin-left: 1rem;
}
h1, h2, h3 {
  text-align: left;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
