<template>
<div>
  <div class="container-fluid text-left">
    <nav class="navbar navbar-dark my-5 px-5 banner-logo">
      <router-link class="navbar-brand" to="/">
        <img alt="Ramaz logo" style="width: 200px; max-width: 100%" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/RamazNewLogo_BLUE_RGB_Large72dpi.jpg/440px-RamazNewLogo_BLUE_RGB_Large72dpi.jpg">
      </router-link>
      <router-link to="/students">
        Reports
      </router-link>
      <a v-if="!alwaysLoggedIn && $store.state.logged_in" href="#" @click.prevent="logout">Log out</a>
    </nav>
  </div>
  <router-view v-if="$store.state.logged_in"></router-view>
  <div v-else class="container">
    <h1>
      Log in please!
    </h1>
    <div class="row">
      <div class="col col-md-6 offset-md-3">
        <form @submit.prevent="login" class="d-flex align-items-center justify-content-center">
          <label for="id_password">Password:</label>
          <input v-model="password" type="password" id="id_password" class="form-control" />
          <button tyoe="submit" class="btn btn-primary">Log in</button>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { get } from 'axios';

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  name: 'App',

  computed: {
    alwaysLoggedIn () {
      return process.env.ALWAYS_LOGGED_IN;
    },
  },
  
  methods: {
    async login () {
      if (!this.password) return;
      const resp = (await get(`/api/login?password=${this.password}`)).data;
      if (resp.status === 'authenticated') {
        document.cookie = "ramaz_bookroom_login=true";
        this.$store.commit('login');
      }
      this.password = '';
    },
    logout () {
      document.cookie = "ramaz_bookroom_login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      this.$store.commit('logout');
    },
  },

  data () {
    return {
      password: '',
    }
  },

  mounted () {
    if (getCookie('ramaz_bookroom_login') === 'true') {
      this.$store.commit('login');
    }
  },
}
</script>

<style>
li {
  list-style-position: inside;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.btn.btn-secondary {
  background: var(--color-1);
  border-color: var(--color-btn);
  color: black;
}
.btn.btn-secondary:hover {
  background: var(--color-btn);
  border-color: var(--color-1);
  color: white;
}
</style>
