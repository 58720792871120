<template>
<div class="container text-left">
  <h1>View student:</h1>
  <v-select v-model="student" :options="studentNames"></v-select>
  <button v-if="student"
          @click="$router.push(`/students/${student.id}`)"
          class="btn btn-primary">Go!</button>
</div>
</template>

<script>
export default {
  computed: {
    studentNames () {
      return this.students.map(o => ({
        ...o,
        label: `${o.FIRST_NAME} ${o.LAST_NAME} (Grade ${o.CURRENT_GRADE})`,
      }));
    },
  },
  data () {
    return {
      students: [],
      student: null,
    }
  },

  async mounted () {
    const { data } = await this.$supabase.from("ramaz_bookroom_student").select("*");
    this.students = data;
  },
}
</script>
